import { FilterSetting, FilterSettingsList, ViewFilter } from 'boot/axios'
import { filterV3FieldNames } from 'stores/watchlist-store'
import { EntityType } from '@stockpulse/typescript-axios'
import { Title } from 'src/types/Title'

interface ColorStop {
  value: number;
  color: string;
}

export interface TreeDataSet {
  name: string,
  value: number,
  itemStyle: {color: string},
  id: number,
  label: {color: string},
  colorValue?: number
}

export interface BuzzSentimentTitlesTableRow {
  type: string,
  id: number,
  name: string,
  buzz: number,
  buzzChange: number,
  sentiment: number,
  sentimentChange: number
  sentimentIcon: string,
  sentimentIconColor: string,
  time: number
}

export interface TitleBuzzSentimentData {
  id: number
  buzz: number,
  sentiment: number,
  sentimentIcon: string,
  sentimentIconColor: string,
  sentimentScore: number,
  time: number
}

export interface TitlesBuzzSentimentDataCollection {[key:number]: TitleBuzzSentimentData}

const colorStops: ColorStop[] = [
  { value: -1, color: getComputedStyle(document.documentElement).getPropertyValue('--q-negative').trim() }, // Red
  { value: 0, color: getComputedStyle(document.documentElement).getPropertyValue('--q-neutral').trim() }, // Gray
  { value: 1, color: getComputedStyle(document.documentElement).getPropertyValue('--q-positive').trim() } // Green
]

export class Auxiliary {
  /**
   * Helper function to clone an object using structuredClone if available,
   * otherwise falls back to JSON.stringify and JSON.parse.
   *
   * @param input - The object to be cloned.
   * @returns A deep clone of the input object.
   */
  static cloneObject<T> (input: T): T {
    if (typeof window.structuredClone === 'function') {
      return window.structuredClone(input)
    } else {
      return JSON.parse(JSON.stringify(input))
    }
  }

  /**
   * Calculates a sentiment score based on the intensity of the sentiment.
   * @param {number} sentimentIntensity The intensity of the sentiment.
   * @return {number} The calculated sentiment score between -100 and +100.
   */
  static calculateSentimentScore (sentimentIntensity: number): number {
    if (sentimentIntensity > 0) {
      return this.roundToPrecision((1 - 1 / (1 + sentimentIntensity)) * 100, 1)
    }
    return this.roundToPrecision((1 - 1 / (1 + Math.abs(sentimentIntensity))) * -100, 1)
  }

  /**
   * @method Auxiliary.roundToPrecision This function rounds a floating-point number to a specified number of
   *           decimal places. The native JavaScript Math.round function only rounds to the nearest integer.
   *           This custom method extends that functionality to allow rounding to a specified number of decimal places,
   *           making it more versatile for scenarios where precision beyond whole numbers is required.
   * @param {number} value The floating-point number to be rounded.
   * @param {number} decimalPlaces The number of decimal places to round to.
   * @return {number} Returns the rounded number.
   */
  static roundToPrecision = function (value: number, decimalPlaces: number): number {
    const scaleFactor = Math.pow(10, decimalPlaces)
    const roundedValue = Math.round(value * scaleFactor) / scaleFactor
    return isNaN(roundedValue) ? 0 : roundedValue
  }

  /**
   * @method Auxiliary.calculateSentimentWidth This function receives a sentiment value and calculates a corresponding
   *         width value. The calculated width is adjusted to fit within a range of 1 to 95.
   * @param {number} sentimentValue The input sentiment value.
   * @return {number} Returns the adjusted width value based on the sentiment.
   */
  static calculateSentimentWidth (sentimentValue: number): number {
    let width = this.roundToPrecision(this.calculateSentimentScore(sentimentValue) / 2, 2) + 50
    if (width > 95) {
      width = 95
    }
    if (width < 1) {
      width = 1
    }
    return width
  }

  /**
   * @method Auxiliary.calculateSentimentWidth This function receives a sentiment value and calculates a corresponding
   *         width value. The calculated width is adjusted to fit within a range of 1 to 95.
   * @param {number} sentimentValue The input sentiment value.
   * @return {string} Returns the adjusted width value based on the sentiment.
   */
  static getIconClassBySentimentValue (sentimentValue: number): string {
    let sentimentIcon = 'spi-face-frown'
    if (sentimentValue > 0.9) {
      sentimentIcon = 'spi-face-grin-stars'
    } else if (sentimentValue > 0.1) {
      sentimentIcon = 'spi-face-grin'
    } else if (sentimentValue > -0.1) {
      sentimentIcon = 'spi-face-meh'
    } else if (sentimentValue > -0.9) {
      sentimentIcon = 'spi-face-frown-open'
    }
    return sentimentIcon
  }

  static getColorForSentimentScore (value: number): string {
    if (value <= colorStops[0].value) {
      return colorStops[0].color
    }
    if (value >= colorStops[colorStops.length - 1].value) {
      return colorStops[colorStops.length - 1].color
    }

    for (let i = 0; i < colorStops.length - 1; i++) {
      if (value >= colorStops[i].value && value < colorStops[i + 1].value) {
        const mix = (value - colorStops[i].value) / (colorStops[i + 1].value - colorStops[i].value)
        return this.interpolateColor(colorStops[i].color, colorStops[i + 1].color, mix)
      }
    }

    // Fallback color
    return colorStops[1].color
  }

  private static interpolateColor (color1: string, color2: string, factor: number): string {
  // Assumes colors are in hex format
    const hex = (color: string): number => parseInt(color.slice(1), 16)
    const lerp = (a: number, b: number, f: number): number => Math.round(a + (b - a) * f)

    const r1 = (hex(color1) >> 16) & 0xff
    const g1 = (hex(color1) >> 8) & 0xff
    const b1 = hex(color1) & 0xff

    const r2 = (hex(color2) >> 16) & 0xff
    const g2 = (hex(color2) >> 8) & 0xff
    const b2 = hex(color2) & 0xff

    const r = lerp(r1, r2, factor)
    const g = lerp(g1, g2, factor)
    const b = lerp(b1, b2, factor)

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
  }

  static offerCSVDownload (csvContent : string[], filename : string): void {
    // offer download to user
    const blob = new Blob([csvContent.join('')], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  static compareObjects (obj1: object, obj2: object): number {
    const str1 = Auxiliary.stableStringify(Auxiliary.sortObject(obj1 as Record<string, unknown>))
    const str2 = Auxiliary.stableStringify(Auxiliary.sortObject(obj2 as Record<string, unknown>))
    if (str1 < str2) {
      return -1
    }
    if (str1 > str2) {
      return 1
    }
    return 0
  }

  static stableStringify (obj: unknown): string {
    const seen: Array<unknown> = []
    return JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.includes(value)) {
          return // Returning undefined when a circular reference is found
        }
        seen.push(value)
      }
      return value
    })
  }

  static sortObject<T extends Record<string, unknown>> (obj: T): T {
    const sortedObj: Record<string, unknown> = {}
    Object.keys(obj).sort().forEach(key => {
      sortedObj[key] = obj[key as keyof typeof obj]
    })
    return sortedObj as T
  }

  static getFilterSettingsListByTitleFilter (titleFilter : ViewFilter, strict = false) : FilterSettingsList {
    const filterList : FilterSettingsList = []

    if (titleFilter.keyEvent.length > 0) {
      // TODO_NEXT: filter key event when available
      // filterList.push({ field: 'keyEvents', comparator: 'gt', value: newTitleFilters.keyEvents })
    }
    if (titleFilter?.region && titleFilter?.region.length > 0) {
      // TODO_NEXT there is no region filter. We have to get all countries for the given region
      // filterList.push({ field: 'region', comparator: 'eq', value: titleFilter.region })
    }

    filterList.push(...Auxiliary.splitSectorFilter(titleFilter?.sector, strict))

    if (titleFilter?.exchange && titleFilter?.exchange.length > 0) {
      filterList.push({ field: filterV3FieldNames.exchange, comparator: 'eq', value: titleFilter.exchange })
    } else if (!strict) {
      // TODO_NEXT Always retrieve exchange information
      // filterList.push({ field: filterV3FieldNames.exchange, comparator: 'gte', value: 1 })
    }
    if (titleFilter?.list && titleFilter?.list.length > 0) {
      // List needs to be a string!
      filterList.push({
        field: filterV3FieldNames.list,
        comparator: 'eq',
        value: titleFilter.list.map(list => `${list}`)
      })
    } else if (!strict) {
      // TODO check
      // filterList.push({ field: entityTypeFilterFieldMapping.list, comparator: 'gte', value: 0 })
    }
    if (titleFilter?.country && titleFilter?.country.length > 0) {
      // 3 Letter CODE
      filterList.push({ field: filterV3FieldNames.country, comparator: 'eq', value: titleFilter.country })
    } else if (!strict) {
      // TODO_NEXT Always retrieve country information
      filterList.push({ field: filterV3FieldNames.country, comparator: 'gte', value: '' })
    }
    if (titleFilter?.asset && titleFilter?.asset.length > 0) {
      // 3 Letter CODE
      filterList.push({ field: filterV3FieldNames.asset, comparator: 'eq', value: titleFilter.asset })
    } else if (!strict) {
      filterList.push({ field: filterV3FieldNames.asset, comparator: 'gte', value: 0 })
    }

    if (titleFilter?.type && titleFilter?.type.length > 0) {
      filterList.push({ field: filterV3FieldNames.type, comparator: 'eq', value: titleFilter.type })
    } else if (!strict) {
      filterList.push({ field: filterV3FieldNames.type, comparator: 'gte', value: '0' })
    }

    if (titleFilter?.keyEvent && titleFilter?.keyEvent.length > 0) {
      titleFilter.keyEvent.forEach(keyEvent => {
        filterList.push({ field: `key_events.4.${keyEvent}.total`, comparator: 'gte', value: '1' })
      })
    }

    return filterList
  }

  static splitSectorFilter (sectorsIds: number[], strict = false) : FilterSetting[] {
    const filters : FilterSetting[] = []

    // group sector ids by type
    const groupedSectors : { [key in filterV3FieldNames]: number[] } = sectorsIds.reduce((acc, sectorId) => {
      const sectorType = Auxiliary.getSectorTypeBySectorId(sectorId)
      if (!acc[sectorType]) {
        acc[sectorType] = []
      }

      acc[sectorType].push(sectorId)

      return acc
    }, {} as { [key in filterV3FieldNames]: number[] })

    const sectorTypes : filterV3FieldNames[] = [
      filterV3FieldNames.sector,
      filterV3FieldNames.group,
      filterV3FieldNames.industry,
      filterV3FieldNames.subindustry
    ]
    // add each group with concrete filter values or as wild card if not strict
    for (const sectorType of sectorTypes) {
      if (groupedSectors[sectorType]) {
        filters.push({ field: sectorType, comparator: 'eq', value: groupedSectors[sectorType] })
      } else if (!strict) {
        // filters.push({ field: sectorType, comparator: 'gte', value: 0 })
      }
    }

    return filters
  }

  static getSectorTypeBySectorId (sectorId: number) : filterV3FieldNames {
    const digitCount = sectorId.toString().length
    if (digitCount >= 8) {
      return filterV3FieldNames.subindustry
    }
    if (digitCount >= 6) {
      return filterV3FieldNames.industry
    }
    if (digitCount >= 4) {
      return filterV3FieldNames.group
    }

    return filterV3FieldNames.sector
  }

  static parseFilterStringToFilterSettings (input : string):FilterSetting[] {
    const parsedData: unknown = JSON.parse(input)
    if (Array.isArray(parsedData)) {
      return parsedData.map(item => {
        const { field, value, comparator } = item
        return {
          field,
          comparator,
          value: Array.isArray(value) ? value : [value] // Assuming value is always an array with single element
        }
      })
    }
    return []
  }

  static parseFilterSettingToViewFilter (filterSettings:FilterSetting[]):ViewFilter {
    const viewFilter : ViewFilter = {
      list: [],
      sector: [],
      exchange: [],
      region: [],
      country: [],
      keyEvent: [],
      asset: [],
      type: []
    }
    filterSettings.forEach(filter => {
      const entity = Auxiliary.getFilterEntityByFieldName(filter.field)
      switch (entity) {
      case 'list':
        if (Array.isArray(filter.value)) {
          viewFilter.list.push(...filter.value.map(Number) as number[])
        } else {
          viewFilter.list.push(parseInt(filter.value as string))
        }
        break
      case 'sector':
      case 'group':
      case 'industry':
      case 'subindustry':
        if (Array.isArray(filter.value)) {
          viewFilter.sector.push(...filter.value.map(Number) as number[])
        } else {
          viewFilter.sector.push(filter.value as number)
        }
        break
      case 'exchange':
        if (Array.isArray(filter.value)) {
          viewFilter.exchange.push(...filter.value.map(Number) as number[])
        } else {
          viewFilter.exchange.push(filter.value as number)
        }
        break
      case 'country':
        if (Array.isArray(filter.value)) {
          viewFilter.country.push(...filter.value as string[])
        } else {
          viewFilter.country.push(filter.value as string)
        }
        break
      case 'asset':
        if (Array.isArray(filter.value)) {
          viewFilter.asset.push(...filter.value.map(Number) as number[])
        } else {
          viewFilter.asset.push(filter.value as number)
        }
        break
      case 'type':
        if (Array.isArray(filter.value)) {
          viewFilter.type.push(...filter.value as EntityType[])
        } else {
          viewFilter.type.push(filter.value as EntityType)
        }
        break

      case 'keyEvent':
        viewFilter.keyEvent.push(parseInt(filter.field.split('.')[2]))
        break
        // TODO_NEXT region is missing at the moment!
        /* case 'region':
           if(Array.isArray(filter.value)){
             viewFilter.region.push(...filter.value as [])
           } else {
             viewFilter.region.push(filter.value as string)
           }
           break */
      }
    })

    return viewFilter
  }

  static getFilterEntityByFieldName (value: string): keyof typeof filterV3FieldNames | undefined {
    if (value.startsWith(filterV3FieldNames.keyEvent)) {
      return 'keyEvent'
    }
    const keys = Object.keys(filterV3FieldNames) as (keyof typeof filterV3FieldNames)[]
    for (const key of keys) {
      if (filterV3FieldNames[key] === value) {
        return key
      }
    }
    return undefined
  }

  static createTreeDataFromTitlesBuzzSentiment (titles : Title[],
    titlesBuzzSentimentData: TitlesBuzzSentimentDataCollection): TreeDataSet[] {
    if (!titles) {
      return []
    }
    // sort the titles based on buzz
    const sortedMapEntries = titles.sort(function (b, a) {
      if (a.buzz < b.buzz) {
        return -1
      }
      if (a.buzz > b.buzz) {
        return 1
      }
      return 0
    })

    const chartLabelDarkColor =
      getComputedStyle(document.documentElement).getPropertyValue('--q-chart-label-dark').trim()

    const first30MapEntries = sortedMapEntries.slice(0, 30)
    first30MapEntries.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.buzz
    }, 0)
    const treeData : TreeDataSet[] = []
    first30MapEntries.forEach((entry) => {
      const titleRealtimeData = titlesBuzzSentimentData[entry.id]
      if (!titleRealtimeData) {
        return
      }
      treeData.push({
        name: entry.name,
        value: titleRealtimeData?.buzz || 0,
        itemStyle: { color: titleRealtimeData?.sentimentIconColor },
        label: { color: titleRealtimeData?.sentimentScore < 0.4 ? 'white' : chartLabelDarkColor },
        id: entry.id,
        colorValue: titleRealtimeData?.sentimentScore
      })
    })
    return treeData
  }

  static getDaily5AMPartitionNameForSource (source : string): string {
    switch (source) {
    case 'www.reddit.com' :
      return 'daily_5am_reddit'
    case 'twitter.com' :
      return 'daily_5am_twitter'
    case 'tiktok.com' :
      return 'daily_5am_tiktok'
    case 'stocktwits.com' :
      return 'daily_5am_stocktwits'
    case 'telegram.org' :
      return 'daily_5am_tiktok'
    case 'discord.org' :
      return 'daily_5am_discord'
    case 'xueqiu.com' :
      return 'daily_5am_xueqiu'
    case 'www.investing.com' :
      return 'daily_5am_investing'
    case 'www.weibo.com' :
      return 'daily_5am_weibo'
    case 'boards.4channel.org' :
      return 'daily_5am_4chan'
    default :
      return 'daily_5am'
    }
  }

  static createTitlesBuzzSentimentTable (titles: Title[],
    titlesBuzzSentimentData: TitlesBuzzSentimentDataCollection,
    prevValues: BuzzSentimentTitlesTableRow[]): BuzzSentimentTitlesTableRow[] {
    const oldIdToSentiment = new Map<number, number>()
    if (prevValues && prevValues.length > 0) {
      prevValues.forEach((item) => {
        oldIdToSentiment.set(item.id, item.sentiment ?? 0)
      })
    }

    return titles.map(title => {
      const titleRealTimeData = titlesBuzzSentimentData[title.id]
      const buzzChange = title.buzz_open === 0 && titleRealTimeData?.buzz === 0
        ? 0
        : title.buzz_open === 0 || titleRealTimeData?.buzz === undefined
          ? Infinity
          : ((titleRealTimeData.buzz - title.buzz_open) / title.buzz_open)

      let sentimentChange = 0
      if (prevValues && prevValues.length > 0) {
        const oldSentiment = oldIdToSentiment.get(title.id)
        sentimentChange = !oldSentiment ? 0 : oldSentiment - (titleRealTimeData?.sentiment ?? 0)
      }

      return {
        type: title.type,
        id: title.id,
        name: title.name,
        buzz: titleRealTimeData?.buzz || 0,
        buzzChange,
        sentiment: titleRealTimeData?.sentiment || 0,
        sentimentIcon: titleRealTimeData?.sentimentIcon,
        sentimentIconColor: titleRealTimeData?.sentimentIconColor,
        time: titleRealTimeData?.time,
        sentimentChange
      }
    })
  }
}
